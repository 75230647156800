
// API
// ----------------------------------------------------------------------


export const HOST_API = 'development' === 'development'? 'https://backend-dot-lab-team-dev-2.uc.r.appspot.com': 'https://backend-dot-lab-marketing-portal.uc.r.appspot.com';
export const SSO_CLIENT_ID = 'development' === 'development'? '515250000165-7rpfgps7obfru35pjlro0aj69p2lv626.apps.googleusercontent.com' : '954529287058-mhb04nih7tsmfk3i6ekiset3miue7g7v.apps.googleusercontent.com';
export const CAPTCHA_KEY = 'development' === 'development'? '6LfesiwmAAAAAGhtN556GUHpYvW_0GHbqDY0NpE_' : '6LeGujAmAAAAAPe59TC2DsIlArnzgvHWvyIb1HDG';
export const WEB_URL = 'development' === 'development'? 'https://showcase.inelabteamdev.com': 'https://showcase.ine.com';
//local setup
// export const HOST_API = 'http://localhost:8080';


