// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDz6Tc83M_YTLzDPVdiVSemIkG0Lg30aws",
  authDomain: "lab-marketing-portal.firebaseapp.com",
  projectId: "lab-marketing-portal",
  storageBucket: "lab-marketing-portal.appspot.com",
  messagingSenderId: "954529287058",
  appId: "1:954529287058:web:a78f99f6e381e7dbe3cf4b",
  measurementId: "G-MQPVE7MG4L"
};

const firebaseConfigdev = {
  apiKey: "AIzaSyBVxj1VpBpZugU3jd3YqUbKJlx2Z7M9b0o",
  authDomain: "lab-team-dev-2.firebaseapp.com",
  projectId: "lab-team-dev-2",
  storageBucket: "lab-team-dev-2.appspot.com",
  messagingSenderId: "515250000165",
  appId: "1:515250000165:web:0c486aac25eb6afc094acc",
  measurementId: "G-1LVBM75E1W"
};

// Initialize Firebase

let app;

if ('development' === 'development') {
  app = initializeApp(firebaseConfigdev);
} else {
  app = initializeApp(firebaseConfig);
}


export const auth = getAuth(app);